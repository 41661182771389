<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{
          backgroundImage:
            'url(' + $store.state.fastPlataforma.banner_plataforma + ')',
          backgroundColor: '#000',
        }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{
                  color: $store.state.fastPersonalizacao.titulo_banner_color
                    ? $store.state.fastPersonalizacao.titulo_banner_color
                    : '#fff',
                  textShadow: '1px 1px #000',
                }"
              >
                {{
                  $store.state.fastPersonalizacao.titulo_banner
                    ? $store.state.fastPersonalizacao.titulo_banner
                    : ""
                }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Lives</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Minhas lives</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section
                            class="fd-app-welcome text-left fast-plataforma-iuea-hide"
                          >
                            <h1
                              v-if="
                                !$store.state.fastPersonalizacao
                                  .minhas_lives_nome
                              "
                              class="aluno_font_color"
                            >
                              Minhas
                              <span>lives</span>
                            </h1>
                            <h1
                              v-else
                              class="aluno_font_color"
                            >
                              {{
                                $store.state.fastPersonalizacao
                                  .minhas_lives_nome
                              }}
                            </h1>
                            <p
                              v-if="
                                !$store.state.fastPersonalizacao
                                  .minhas_lives_nome
                              "
                              class="aluno_font_color"
                            >
                              Veja as suas lives agendadas
                            </p>
                            <div
                              class="fd-welcome-form-effect fast-plataforma-iuea-hide"
                            >
                              <img
                                :src="
                                  require('@/assets/images/form-effect-01.png')
                                "
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <section
                            class="fd-app-welcome text-left d-none fast-menu-lives-aluno-iuea mt-4 mb-4 ml-4"
                          >
                            <h1
                              v-if="
                                !$store.state.fastPersonalizacao
                                  .minhas_lives_nome
                              "
                              class="aluno_font_color"
                            >
                              Minhas lives
                            </h1>
                            <h1
                              v-else
                              class="aluno_font_color"
                            >
                              {{
                                $store.state.fastPersonalizacao
                                  .minhas_lives_nome
                              }}
                            </h1>
                          </section>

                          <!-- /fd-app-welcome -->
                          <div class="col-12 mt-2">
                            <div class="row">
                              <div
                                class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1"
                              >
                                <h4
                                  v-if="
                                    !$store.state.fastPersonalizacao
                                      .minhas_lives_nome
                                  "
                                  class="aluno_font_color"
                                >
                                  Lista de Lives ({{ fastLiveFiltro.length }})
                                </h4>
                                <h4
                                  v-else
                                  class="aluno_font_color"
                                >
                                  Encontradas ({{ fastLiveFiltro.length }})
                                </h4>
                              </div>
                              <div class="col-12 table-responsive">
                                <table class="table table-sm table-striped">
                                  <thead class="thead-dark">
                                    <tr>
                                      <th class="text-center">
                                        #
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Título da live</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Data/hora</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Duração</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Acesso</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Status</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastLiveFiltro.length">
                                    <tr
                                      v-for="(live, index) in pageOfLive"
                                      :key="live.id_live"
                                    >
                                      <td class="text-center align-middle">
                                        <small>{{ index + 1 }}</small>
                                      </td>
                                      <td class="align-middle">
                                        <small>{{ live.titulo_live }}</small>
                                      </td>
                                      <td class="text-center align-middle">
                                        <small>{{
                                                 new Date(live.data_live)
                                                   .toLocaleString()
                                                   .split(" ")[0]
                                               }}
                                          {{ live.hora_live }}</small>
                                      </td>
                                      <td class="text-center align-middle">
                                        <small>{{ live.duracao_live }}</small>
                                      </td>
                                      <td class="text-center align-middle">
                                        <small>{{
                                          live.acesso == "R"
                                            ? "Restrito"
                                            : "Público"
                                        }}</small>
                                      </td>
                                      <td class="text-center align-middle">
                                        <small
                                          v-if="live.status == 'A'"
                                          class="text-success"
                                        >Agendada</small>
                                        <small
                                          v-else-if="live.status == 'E'"
                                          class="text-warning"
                                        >Em execução</small>
                                        <small
                                          v-else-if="live.status == 'C'"
                                          class="text-danger"
                                        >Cancelada</small>
                                        <small
                                          v-else-if="live.status == 'F'"
                                          class="text-secondary"
                                        >Finalizada</small>
                                      </td>
                                      <td class="text-center align-middle">
                                        <a
                                          v-if="
                                            live.status == 'A' ||
                                              live.status == 'E'
                                          "
                                          class="btn btn-sm btn-primary mr-2"
                                          :href="'/live/' + live.id_live"
                                        >
                                          <small>Acessar</small>
                                        </a>
                                        <a
                                          v-else-if="
                                            live.status == 'F' &&
                                              live.video_registro
                                          "
                                          class="btn btn-sm btn-primary mr-2"
                                          target="_blank"
                                          :href="live.video_registro"
                                        >
                                          <small>Gravação</small>
                                        </a>

                                        <button
                                          class="btn btn-sm btn-success"
                                          @click="exibeModalAnexosLive(live)"
                                        >
                                          <small>Anexos</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="10"
                                :items="fastLiveFiltro"
                                @changePage="pageOfLive = $event"
                              />
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modal -->
    <modal
      name="modalAnexosLive"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Anexos da live</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAnexosLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="col-12 mt-4">
                <ul
                  v-if="fastLiveAnexos.length"
                  class="list-group"
                >
                  <li
                    v-for="arquivo in fastLiveAnexos"
                    :key="arquivo.id_live_arquivo"
                    class="list-group-item"
                  >
                    <a
                      :href="arquivo.path"
                      download
                    >{{
                      arquivo.descricao ? arquivo.descricao : "Sem descrição"
                    }}</a>
                  </li>
                </ul>
                <ul
                  v-else
                  class="list-group"
                >
                  <li class="list-group-item">
                    Nenhum anexo
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import muralAvisos from "../components/MuralAvisos";

export default {
  name: "HomeInternoAluno",
  components: {
    Pagination,
    muralAvisos,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Lives
      fastLiveTotal: [],
      fastLiveFiltro: [],
      pageOfLive: [],
      fastLiveEditar: {
        id_live: "",
        API_TOKEN: "",
        nr_maximo_sala: "",
        data_live: "",
        hora_live: "",
        id_usuario_tutor: "",
        id_usuario_moderador: "",
        detalhes: "",
        titulo_live: "",
        id_plataforma: 0,
        status: "",
        video_registro: "",
        duracao_live: "",
        acesso: "R",
        id_usuario_responsavel: "",
        tipo: "",
        participantes: [],
      },
      fastLiveAnexos: [],
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.getMinhasLives();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getMinhasLives() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_live/lista_usuario_nova?id_live=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          let lives = Array.from(new Set(obj.map((a) => a.id_live))).map(
            (id_live) => {
              return obj.find((a) => a.id_live === id_live);
            }
          );

          this.fastLiveTotal = lives;
          this.fastLiveFiltro = lives;
        } else {
          this.fastLiveTotal = [];
          this.fastLiveFiltro = [];
        }

        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;

        console.log("getMinhasLives", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalAnexosLive(live) {
      this.$store.state.fastCarregando = true;
      this.getLiveAnexos(live)
        .then((arquivos) => {
          this.fastLiveEditar = live;
          this.fastLiveAnexos = arquivos;
          this.showModal("modalAnexosLive");
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.fastCarregando = false;
        });
    },
    async getLiveAnexos(live) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_live_arquivo/lista?id_live=" +
              live.id_live,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();

          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
};
</script>

<style></style>
