<template>
  <div class="fd-app-mode-ead-header-mural">
    <div class="d-flex">
      <img :src="require('@/assets/images/app/alarm-icon.png')">
      <h4 class="font-weight-bold">
        Mural de avisos
      </h4>
    </div>
    <ul v-if="fastMuralLoading">
      <li>
        <a href="#">Carregando avisos...</a>
      </li>
    </ul>
    <ul v-else-if="fastMural.length">
      <li
        v-for="m in fastMural"
        :key="m.id_mural"
      >
        <a
          class="fast-iuea-color aluno_font_color"
          :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/mural'"
        >Mural - {{ m.titulo_publicacao }}</a>
      </li>
    </ul>
    <ul v-else>
      <li>
        <a href="#">Nenhum aviso</a>
      </li>
    </ul>
    <div class="text-right">
      <a
        class="fast-iuea-color"
        :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/mural'"
      >Ver mais</a>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "MuralAvisos",
  mixins: [methods],
  data: function () {
    return {
      fastMural: [],
      fastMuralLoading: true
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.$store.state.fastPermissoes.id_plataforma = this.$route.params.id_plataforma;
      this.getMuralPaginado();
    }
  },
  methods: {
    // async getMural() {
    //   try {
    //     let resp = await fetch(
    //       settings.endApiFastEad +
    //         "api/fast_mural/lista_ead?id_plataforma=" +
    //         this.$route.params.id_plataforma +
    //         "&id_mural=0",
    //       this.fastAjaxOptions("GET")
    //     );
    //     let json = await resp.json();
    //     let obj = Array.from(json);
    //     if (obj.length) {
    //       obj.forEach((a, index) => {
    //         if (index < 3) this.fastMural.push(a);
    //       });
    //     }
    //     this.fastMuralLoading = false
    //   } catch (e) {
    //     console.log("Erro", e);
    //     this.fastMuralLoading = false
    //   }
    // },
    async getMuralPaginado() {
      
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_mural/lista_mural_paginado?PageIndex=1&PageSize=3&IdPlataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();  
        this.fastMuralLoading = false  
        if (json.TotalResults) {
          this.fastMural = json.Data
        }       
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeDataPublicacao(mural) {
      let dataPublicacao =
        "Publicado em " +
        mural.split("T")[0] +
        " às " +
        mural.split("T")[1].split(".")[0];
      return dataPublicacao.replace(/-/g, "/");
    },
  },
};
</script>

<style></style>
